import React from 'react';
import Table, { OverflowTableWrapper } from '../../components/Table';
import DatabasePage, { LastRow } from '../../components/DatabasePage';
import MariaDbColorless from '../../images/databases/MariaDbColorless.svg';

export default function MariaDb() {
  return (
    <DatabasePage
      title="Ametnes for MariaDB"
      description="Ametnes for MariaDB is a fully managed SQL database, deployable in the cloud of your choice. Snap it into your existing workflows with the click of a button, automate away the mundane tasks, and focus on building your core apps."
      featuresTitle="Ametnes MariaDB Key Features"
      DbIcon={MariaDbColorless}
    >
      <OverflowTableWrapper>
        <Table>
          <thead>
            <tr>
              <th>Features</th>
              <th>MariaDB 10.5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Free Tier</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Automated backups</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>1 day backup retention</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Up to 1 database per account</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Shared Instance</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Secure TLS access</td>
              <td>✕</td>
            </tr>
            <LastRow />
          </tbody>
        </Table>
      </OverflowTableWrapper>
    </DatabasePage>
  );
}
